<template>
  <div class="hospital-manage-content">
    <part-title title="无双员工配置"></part-title>
    <el-scrollbar style="height: 100%">
      <div class="search">
        <span class="el-span">员工姓名：</span>
        <el-input size="small" class="el-input" v-model="searchOptions.name" placeholder="请输入员工姓名"></el-input>
        <span class="el-span">状态：</span>
        <el-select class="el-select" size="small" v-model="searchOptions.status" placeholder="请选择" clearable>
          <el-option
            v-for="item in options"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
        <el-button
          size="small"
          @click="onSearch"
          class="search-btn">
          查询
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="add-btn"
          @click="onAddStaff">
          <i class="el-icon-plus"></i>
          添加员工
        </el-button>
      </div>
      <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        type="index"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="name"
        label="员工姓名">
      </el-table-column>
      <el-table-column
        prop="phone"
        label="钉钉手机号">
      </el-table-column>
      <el-table-column
        prop="creatorName"
        label="添加人">
      </el-table-column>
      <el-table-column
        prop="status"
        label="账号状态">
        <template slot-scope="scope">
          {{ scope.row.status === 0 ? '已停用': '已启用' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createAt"
        label="添加时间"
        width="200">
        <template slot-scope="scope">
            <span>{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="120">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" :content="scope.row.status === 0 ? '冻结': '启用'" placement="top">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="onChangeStatus(scope.row)">
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px;"></div>
    <!-- 分页 -->
    <Page
        @handleSizeChange="sizeChange"
        @handleCurrentChange="pageChange"
        :current-page="searchOptions.pageNo"
        :page-size="searchOptions.pageSize"
        :total="totalPage"
    ></Page>
    </el-scrollbar>
    <el-drawer
      title="添加员工信息"
      :visible.sync="isVisibleAddHospitalDrawer"
      @close="beforeClose"
      size="400px">
      <div style="margin: 24px">
        <el-form
          :model="ruleStaffForm"
          :rules="rules"
          ref="ruleStaffForm"
          label-width="100px"
          label-position="right"
          size="small">
          <el-form-item label="钉钉手机号" prop="phone">
            <!-- <el-select
            v-model="ruleStaffForm.staffInfo"
            filterable
            style="width: 100%;"
            placeholder="请输入钉钉手机号"
            @change="selectStuff"
            @input.native="filterData"
            ref="searchSelect">
            <el-option
              v-for="item in stuffInfoList"
              :key="item.phone"
              :label="item.phone"
              :value="item.phone">
            </el-option>
           </el-select> -->
           <el-autocomplete placeholder="请输入钉钉手机号" v-model="ruleStaffForm.phone"
           :maxlength="11"
           oninput="value=value.replace(/[^\d]/g,'')"
           :fetch-suggestions="querySearchPhone"  @select="onSearchPhone" style="width:100%"></el-autocomplete>
          </el-form-item>
          <el-form-item label="员工姓名">
            <el-input v-model="ruleStaffForm.name"  :disabled="true" placeholder="自动匹配员工姓名"></el-input>
          </el-form-item>
        </el-form>
        <div class="line"></div>
        <div class="drawer-footer">
          <el-button class="footer-btn" @click="onCancel" size="small">取消</el-button>
          <el-button class="footer-btn" type="primary" @click="handelAdd" size="small">保存</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import PartTitle from '@/components/Part'
import Page from '@/components/Page'
import { getStaffList, getStaffInfo, addStuff, changeStuffStatus } from '@/serve/module/staffManage'
// import { dataToFile } from '@/utils/file'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    Page
  },
  data () {
    return {
      moment: moment,
      tableData: [],
      handleOpts: {
      },
      totalPage: 0, // 总条数

      isVisibleAddHospitalDrawer: false,
      ruleStaffForm: {
        name: '',
        phone: ''
      },
      rules: {
        staffInfo: [
          { required: true, message: '请输入钉钉手机号' }
        ]
      },
      isEditDrawer: false,

      changeHospitalStatusHandle: false,

      options: [{
        code: 0,
        name: '冻结'
      }, {
        code: 1,
        name: '启用'
      }],
      searchOptions: {
        pageSize: 20,
        pageNo: 1
      },
      stuffInfoList: [],
      patientInfoOptions: [],
      userInfo: {}
    }
  },
  mounted () {
    if (sessionStorage.getItem('Authorization')) {
      this.getStaffList()
    }
  },
  methods: {
    getStaffList (params) {
      getStaffList(params).then(res => {
        if (res && res.code === 200) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    sizeChange (val) {
      this.searchOptions.pageSize = val
      this.getStaffList(this.searchOptions)
    },
    pageChange (val) {
      this.searchOptions.pageNo = val
      this.getStaffList(this.searchOptions)
    },
    onCancel () {
      this.isVisibleAddHospitalDrawer = false
    },
    beforeClose () {
      this.ruleStaffForm = {
        name: '',
        phone: ''
      }
    },
    onAddStaff () {
      this.patientInfoOptions = []
      this.$nextTick(() => {
        this.isVisibleAddHospitalDrawer = true
      })
    },
    onSearch () {
      if (this.searchOptions.name === '') {
        this.$delete(this.searchOptions, 'name')
      }
      this.getStaffList(this.searchOptions)
    },
    changeStuffStatus (params) {
      changeStuffStatus(params).then(res => {
        if (res.code === 200) {
          if (params.status === 'enable') {
            this.$message.success('启用成功')
          } else {
            this.$message.success('禁用成功')
          }
          this.getStaffList()
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },
    async onChangeStatus (row) {
      if (row.status === 1) {
      // 启用
        row.status = 0
        this.changeStuffStatus({
          id: row.id,
          status: 'enable'
        })
      } else {
      // 停用
        row.status = 1
        this.$confirm('确定冻结员工账号？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.changeStuffStatus({
            id: row.id,
            status: 'disable'
          })
        }).catch(() => {
        //
        })
      }
    },
    getStaffInfo (phone) {
      getStaffInfo({
        phone: phone
      }).then(res => {
        if (res.code === 200) {
          const list = [res.data]
          for (let i = 0; i < list.length; i++) {
            if (this.patientInfoOptions && this.patientInfoOptions.length === 0) {
              this.patientInfoOptions.push({
                value: list[i].phone + '(' + list[i].name + ')'
              })
            } else {
              this.patientInfoOptions.forEach((v, index) => {
                if (v.value === list[i].phone + '(' + list[i].name + ')') {
                  return
                }
                this.patientInfoOptions.push({
                  value: list[i].phone + '(' + list[i].name + ')'
                })
              })
            }
          }
        } else {
          this.patientInfoOptions = []
          this.$message.error(res.message)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },
    handelAdd () {
      this.$refs.ruleStaffForm.validate((valid) => {
        if (valid) {
          addStuff({
            phone: this.ruleStaffForm.phone
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.isVisibleAddHospitalDrawer = false
              this.getStaffList()
            } else {
              this.$message.error(res.message)
            }
          }).catch(() => {
            this.$message.error('网络错误')
          })
        }
      })
    },
    async querySearchPhone (queryString, cb) {
      console.log('2', this.patientInfoOptions)
      this.patientInfoOptions = []
      if (queryString) {
        await this.getStaffInfo(queryString)
        cb(this.patientInfoOptions)
      }
    },
    onSearchPhone (item) {
      this.ruleStaffForm.phone = item.value.substring(0, item.value.lastIndexOf('('))
      this.ruleStaffForm.name = item.value.substring(item.value.lastIndexOf('(') + 1, item.value.lastIndexOf(')'))
    }
  }
}
</script>
<style scoped lang="scss">

.search {
  margin-bottom: 12px;
    .el-input {
      width: 206px;
      margin-right: 24px;
    }
    .el-select {
      width: 234px;
      margin-right: 24px;
    }
    .el-span {
      font-weight: 400;
      font-size: 14px;
      color: #606266;
    }
    .add-btn {
      float: right;
    }
  }
.hospital-manage-content {
  margin: 0 24px;
  .content-btn {
    float: right;
    margin-bottom: 12px;
    font-size: 14px;
  }
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}
.line {
  height: 1px;
  width: 400px;
  position: fixed;
  bottom: 64px;
  right: 0;
  box-shadow: inset 0px 1px 0px #E7E8EA;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 48px;
  right: 20px;
}
::v-deep .el-button--text {
  font-size: 12px;
  font-weight: 400;
}
.page,.page-shadow {
  background: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 210px;
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
::v-deep .el-form-item {
  margin-bottom: 12px;
}
</style>
